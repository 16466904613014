
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import {mask} from 'vue-the-mask'
import { useRouter } from "vue-router";
import {active} from "@/core/data/genericData";
export default defineComponent({
  name: "edit-categories-modal", 
  directives: {mask}, 
  props: {
    data: {
        type: Object
    }
  },
  components: {    
    },    
  setup(props) {    
    const active_list_data = active;
    // this.$forceUpdate();
    const router = useRouter();    
    var formData = ref({})
    const store = useStore();
    const setFormData = async (data) => {    
        console.log(data.ids);
        var j = 0;

        try {

        var values = { "grade_id": data.ids, "page": 1, "records_per_page": 1 }
         await store.dispatch(Actions.CUST_GET_GRADE_LIST, values).then(({ data }) => {       
          formData.value = {
            id : data.grade_id,
            name: data.grade_name,
            standard_select: data.standard_id,
            active : data.active,
            };
          console.log(formData.value)
          })
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }

    const standardgrade = ref([]);
    const setStandardData = async (data) => {
        //loading.value = true;
        try {
          var values = {"search_term" : data}
          await store.dispatch(Actions.CUST_GET_GRADE_STANDARD, values).then(({ data }) => {
          standardgrade.value = data.page_data;
          console.log(standard.value)
          //loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }


    onMounted( async () => {      
      await setFormData(props.data);
      await setProductGradedata(standard.value)
      await setStandardData("")
      });  

    
    const formRef = ref<null | HTMLFormElement>(null);
    const editGradeModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);    

    const rules = ref({
      name: [
        {
          required: true,
          message: "Grade name is required",
          trigger: "change",  
             
        },  
      ],   
    });
    const standard = ref([]);
    const setProductGradedata = async (data) => {
      // const db_data = {}
      //   //loading.value = true;
      //   try {
      //     await store.dispatch(Actions.CUST_GET_PRODUCT_STANDARD_MASTER_LIST, db_data).then(({ data }) => {
      //     standard.value = data.standard_list;
      //     //loading.value = false;
      //   })
      //   .catch(({ response }) => {
      //     console.log(response);        
      //   });
        
      // } catch (e) {
      //   console.log(e);
      // }

    }

    const setGradeData = async (data) => {   
      //var user = JSON.parse(JwtService.getToken());       
      const db_data = {
            "grade_id" : data.id,
            "grade_name": data.name,
            "standard_id": data.standard_select,
            "active": data.active,
        }

        await store.dispatch(Actions.CUST_UPDATE_GRADE, db_data).then(({ data }) => {
        console.log(data);
        
        if (data)
        {
            setTimeout(() => {
              loading.value = false;
//
              Swal.fire({
                text: "Product Grade has been successfully Updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editGradeModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
        })
      .catch(({ response }) => {
        console.log(response);        
      });
    };

   const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await setGradeData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }

      });  
    }
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      setProductGradedata,
      standardgrade,
      editGradeModalRef,
      setStandardData,
      active_list_data,
    };
  },
});
